const packageJSON = require('../package.json');

const { name, applicationName } = packageJSON;

module.exports = {
  vault: {
    url: `https://vault.peya.co/v1/secret/web-micro-site-${name}/stg`,
    enabled: true,
  },
  apiGateway: {
    url: 'http://client-api-gateway.stg.peja.co',
    timeout: 5000,
  },
  authServices: {
    url: 'http://auth.stg.peja.co',
    timeout: 5000,
  },
  cors: {
    whitelist: [],
  },
  session: {
    cookieName: `__Secure-${applicationName}.${name}.sid`,
    sessionName: `${applicationName}.${name}`,
    cookieConfig: {
      httpOnly: true,
      secure: true,
      sameSite: 'lax',
      maxAge: 60000 * 90,
    },
  },
  fwf: {
    httpRequestTimeout: 1500,
    flagTimeToLive: 10,
    localFwfUrl: 'http://fwf-client-api.stg.peja.co',
  },
  gtm: {
    gtmId: 'GTM-K7NSVK5',
    auth: 'lyFrrDjOHN50TtuPAJaB1Q',
    preview: 'env-160',
  },
  consumers: {
    'pedidosya-web': {
      key: 'cGVkaWRvc3lhLXdlYg==',
      domains: {
        ar: 'https://stg-mweb.pedidosya.com.ar',
        bo: 'https://stg-mweb.pedidosya.com.bo',
        cl: 'https://stg-mweb.pedidosya.cl',
        cr: 'https://stg-mweb.pedidosya.cr',
        do: 'https://stg-mweb.pedidosya.com.do',
        ec: 'https://stg-mweb.pedidosya.com.ec',
        gt: 'https://stg-mweb.pedidosya.com.gt',
        hn: 'https://stg-mweb.pedidosya.com.hn',
        ni: 'https://stg-mweb.pedidosyani.com.ni',
        pa: 'https://stg-mweb.pedidosya.com.pa',
        pe: 'https://stg-mweb.pedidosya.com.pe',
        py: 'https://stg-mweb.pedidosya.com.py',
        sv: 'https://stg-mweb.pedidosyasv.com.sv',
        uy: 'https://stg-mweb.pedidosya.com.uy',
        ve: 'https://stg-mweb.pedidosya.com.ve',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'dev-pedidosya-web': {
      key: 'ZGV2LXBlZGlkb3N5YS13ZWI=',
      domains: {
        ar: 'https://dev-mweb.pedidosya.com.ar',
        bo: 'https://dev-mweb.pedidosya.com.bo',
        cl: 'https://dev-mweb.pedidosya.cl',
        cr: 'https://dev-mweb.pedidosya.cr',
        do: 'https://dev-mweb.pedidosya.com.do',
        ec: 'https://dev-mweb.pedidosya.com.ec',
        gt: 'https://dev-mweb.pedidosya.com.gt',
        hn: 'https://dev-mweb.pedidosya.com.hn',
        ni: 'https://dev-mweb.pedidosyani.com.ni',
        pa: 'https://dev-mweb.pedidosya.com.pa',
        pe: 'https://dev-mweb.pedidosya.com.pe',
        py: 'https://dev-mweb.pedidosya.com.py',
        sv: 'https://dev-mweb.pedidosyasv.com.sv',
        uy: 'https://dev-mweb.pedidosya.com.uy',
        ve: 'https://dev-mweb.pedidosya.com.ve',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'local-pedidosya-web': {
      key: 'bG9jYWwtcGVkaWRvc3lhLXdlYg==',
      domains: {
        ar: 'http://localhost:3001',
        bo: 'http://localhost:3001',
        cl: 'http://localhost:3001',
        cr: 'http://localhost:3001',
        do: 'http://localhost:3001',
        ec: 'http://localhost:3001',
        gt: 'http://localhost:3001',
        hn: 'http://localhost:3001',
        ni: 'http://localhost:3001',
        pa: 'http://localhost:3001',
        pe: 'http://localhost:3001',
        py: 'http://localhost:3001',
        sv: 'http://localhost:3001',
        uy: 'http://localhost:3001',
        ve: 'http://localhost:3001',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'courier-business-web-local': {
      key: 'Y291cmllci1idXNpbmVzcy13ZWItbG9jYWw=',
      domains: {
        ar: 'http://localhost:8085',
        cl: 'http://localhost:8085',
        pe: 'http://localhost:8085',
        uy: 'http://localhost:8085',
        ve: 'http://localhost:8085',
      },
      redirectUrl:
        '/api/courier-business-main/login?c=.{c}&at={at}&localLogin=true',
      appToken: 'courierAppToken',
      termsAndConditionsConfig: {
        businessType: 'COURIER_BUSINESS',
        TYC: '/about/courier-business/tyc',
        PRIVACY_POLICIES: '/about/courier-business/privacy',
      },
    },
    'courier-business-web': {
      key: 'Y291cmllci1idXNpbmVzcy13ZWI=',
      domains: {
        ar: 'https://stg-courier-web.pedidosya.com.ar',
        bo: 'https://stg-courier-web.pedidosya.com.bo',
        cl: 'https://stg-courier-web.pedidosya.cl',
        cr: 'https://stg-courier-web.pedidosya.cr',
        do: 'https://stg-courier-web.pedidosya.com.do',
        ec: 'https://stg-courier-web.pedidosya.com.ec',
        gt: 'https://stg-courier-web.pedidosya.com.gt',
        hn: 'https://stg-courier-web.pedidosya.com.hn',
        ni: 'https://stg-courier-web.pedidosyani.com.ni',
        pa: 'https://stg-courier-web.pedidosya.com.pa',
        pe: 'https://stg-courier-web.pedidosya.com.pe',
        py: 'https://stg-courier-web.pedidosya.com.py',
        sv: 'https://stg-courier-web.pedidosyasv.com.sv',
        uy: 'https://stg-courier-web.pedidosya.com.uy',
        ve: 'https://stg-courier-web.pedidosya.com.ve',
      },
      redirectUrl: '/api/courier-business-main/login?c=.{c}&at={at}',
      appToken: 'courierAppToken',
      termsAndConditionsConfig: {
        businessType: 'COURIER_BUSINESS',
        TYC: '/about/courier-business/tyc',
        PRIVACY_POLICIES: '/about/courier-business/privacy',
      },
    },
  },
};
