import styled from 'styled-components';
import { getSpacing } from '@/src/utils/styles/fenixHelpers';

/**
 * Components
 */
import Button from '@pedidosya/web-fenix/atoms/Button';

export const SocialContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SocialWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 32px;
  z-index: 1;
  padding: ${getSpacing(4)};
`;

export const SocialLogo = styled.img`
  margin: auto;
  height: 24px;
`;

export const SocialButton = styled(Button).attrs((props) => ({
  label: props.label,
  size: 'large',
  hierarchy: 'secondary',
  fullWidth: true,
}))`
  height: 40px;
  border-color: ${({ theme }) =>
    theme.color('shape-color-stroke-action-enabled-default')};
  padding: ${getSpacing(0, 24)};

  > div span {
    font-weight: 500;
    padding-left: 26px;
  }
`;
