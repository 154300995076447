import getInstance from '@/external-libs/axios';

/**
 * Utils
 */
import { googleConfigURL } from '@/src/constants/urls/apiURLs';
import ServiceResponse from '@/src/models/ServiceResponse';

async function getGoogleConfig() {
  try {
    const axios = getInstance();
    const { data } = await axios.get(googleConfigURL);

    return new ServiceResponse({ data });
  } catch (error) {
    return new ServiceResponse({ error });
  }
}

export default getGoogleConfig;
