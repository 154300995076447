import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Assets
 */
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';

/**
 * Components
 */
import Typography from '@pedidosya/web-fenix/atoms/Typography';

/**
 * Utils
 */
import { getSpacing } from '@/src/utils/styles/fenixHelpers';

const BottomSheetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${getSpacing(8, 0)};
  color: black;
`;

function BottomSheetItem({ onClick, label, elementId }) {
  return (
    <BottomSheetContainer elementId={elementId} onClick={onClick}>
      <Typography token="font-body-midcontrast-sentence-medium">
        {label}
      </Typography>
      <ChevronRight />
    </BottomSheetContainer>
  );
}

BottomSheetItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default BottomSheetItem;
