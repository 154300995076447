import { useContext } from 'react';

/**
 * Providers
 */
import { WindowContext } from '@/src/providers/WindowProvider';

/**
 * Utils
 */
import { isLocal } from '@/src/utils/environment';

function useBaseURL() {
  const windowObject = useContext(WindowContext);

  if (windowObject) {
    const hostname = isLocal()
      ? windowObject.location.host
      : windowObject.location.hostname;
    return `${windowObject.location.protocol}//${hostname}`;
  }

  return '';
}

export default useBaseURL;
