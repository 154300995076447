const packageJSON = require('../package.json');

const { name, applicationName } = packageJSON;

module.exports = {
  vault: {
    url: `https://vault.peya.co/v1/secret/web-micro-site-${name}/live`,
    enabled: true,
  },
  apiGateway: {
    url: 'http://client-api-gateway.live.peja.co',
    timeout: 10000,
  },
  authServices: {
    url: 'http://auth.live.peja.co',
    timeout: 10000,
  },
  cors: {
    whitelist: [],
  },
  session: {
    cookieName: `__Secure-${applicationName}.${name}.sid`,
    sessionName: `${applicationName}.${name}`,
    cookieConfig: {
      httpOnly: true,
      secure: true,
      sameSite: 'lax',
      maxAge: 60000 * 60,
    },
  },
  fwf: {
    httpRequestTimeout: 250,
    flagTimeToLive: 20,
    localFwfUrl: 'http://fwf-client-api.live.peja.co',
  },
  gtm: {
    gtmId: 'GTM-NZMRRPN',
    auth: 'uWwlTeaR5y40mk39Elx93w',
    preview: 'env-2',
  },
  consumers: {
    'pedidosya-web': {
      key: 'cGVkaWRvc3lhLXdlYg==',
      domains: {
        ar: 'https://www.pedidosya.com.ar',
        bo: 'https://www.pedidosya.com.bo',
        cl: 'https://www.pedidosya.cl',
        cr: 'https://www.pedidosya.cr',
        do: 'https://www.pedidosya.com.do',
        ec: 'https://www.pedidosya.com.ec',
        gt: 'https://www.pedidosya.com.gt',
        hn: 'https://www.pedidosya.com.hn',
        ni: 'https://www.pedidosyani.com.ni',
        pa: 'https://www.pedidosya.com.pa',
        pe: 'https://www.pedidosya.com.pe',
        py: 'https://www.pedidosya.com.py',
        sv: 'https://www.pedidosyasv.com.sv',
        uy: 'https://www.pedidosya.com.uy',
        ve: 'https://www.pedidosya.com.ve',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'courier-business-web': {
      key: 'Y291cmllci1idXNpbmVzcy13ZWI=',
      domains: {
        ar: 'https://envios.pedidosya.com.ar',
        bo: 'https://envios.pedidosya.com.bo',
        cl: 'https://envios.pedidosya.cl',
        cr: 'https://envios.pedidosya.cr',
        do: 'https://envios.pedidosya.com.do',
        ec: 'https://envios.pedidosya.com.ec',
        gt: 'https://envios.pedidosya.com.gt',
        hn: 'https://envios.pedidosya.com.hn',
        ni: 'https://envios.pedidosyani.com.ni',
        pa: 'https://envios.pedidosya.com.pa',
        pe: 'https://envios.pedidosya.com.pe',
        py: 'https://envios.pedidosya.com.py',
        sv: 'https://envios.pedidosyasv.com.sv',
        uy: 'https://envios.pedidosya.com.uy',
        ve: 'https://envios.pedidosya.com.ve',
      },
      redirectUrl: '/api/courier-business-main/login?c=.{c}&at={at}',
      appToken: 'courierAppToken',
      termsAndConditionsConfig: {
        businessType: 'COURIER_BUSINESS',
        TYC: '/about/courier-business/tyc',
        PRIVACY_POLICIES: '/about/courier-business/privacy',
      },
    },
    /* Delete this one after a few days */
    'micro-site-courier-web-business': {
      key: 'bWljcm8tc2l0ZS1jb3VyaWVyLXdlYi1idXNpbmVzcw==',
      domains: {
        ar: 'https://envios.pedidosya.com.ar',
        bo: 'https://envios.pedidosya.com.bo',
        cl: 'https://envios.pedidosya.cl',
        cr: 'https://envios.pedidosya.cr',
        do: 'https://envios.pedidosya.com.do',
        ec: 'https://envios.pedidosya.com.ec',
        gt: 'https://envios.pedidosya.com.gt',
        hn: 'https://envios.pedidosya.com.hn',
        ni: 'https://envios.pedidosyani.com.ni',
        pa: 'https://envios.pedidosya.com.pa',
        pe: 'https://envios.pedidosya.com.pe',
        py: 'https://envios.pedidosya.com.py',
        sv: 'https://envios.pedidosyasv.com.sv',
        uy: 'https://envios.pedidosya.com.uy',
        ve: 'https://envios.pedidosya.com.ve',
      },
      redirectUrl: '/api/backend/forceLogin?c=.{c}&at={at}',
      appToken: 'courierAppToken',
      termsAndConditionsConfig: {
        businessType: 'COURIER_BUSINESS',
        TYC: '/about/courier-business/tyc',
        PRIVACY_POLICIES: '/about/courier-business/privacy',
      },
    },
    /* ******************************** */
  },
};
