import {
  TEST_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STG_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
} from '@/src/constants/environments';

import { getEnvironmentFromConfig } from '../../config';

export function getEnvironment() {
  const env = getEnvironmentFromConfig();
  return env || 'unknown';
}

export const isLocal = () => getEnvironment() === LOCAL_ENVIRONMENT;
export const isDevelopment = () => getEnvironment() === DEV_ENVIRONMENT;
export const isStaging = () => getEnvironment() === STG_ENVIRONMENT;
export const isProduction = () => getEnvironment() === PROD_ENVIRONMENT;
export const isTest = () => getEnvironment() === TEST_ENVIRONMENT;
