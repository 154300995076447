// Fallback for building time, not affect when is server up
const env = process.env.ENV || 'local';

/**
 * Retrieve value of the key from configs
 * @param {string} key
 * @returns object
 */
function get(key) {
  // eslint-disable-next-line global-require
  const config = require(`./${env}`);
  return config[key] || null;
}

/**
 * Retrieve value of the env
 * @returns string
 */
function getEnvironmentFromConfig() {
  return env;
}

/**
 * Set the application secrets from Vault for easy access
 * @param {object} values
 */
function setAppSecrets(values) {
  global.ApplicationSecrets = values;
}

/**
 * Retrieve the current application secrets
 * @returns object
 */
function getAppSecrets() {
  return global.ApplicationSecrets || {};
}

module.exports = {
  get,
  getEnvironmentFromConfig,
  getAppSecrets,
  setAppSecrets,
};
