export const FACEBOOK_LOGIN_REDIRECT = 'facebook';

export const SOCIAL_NETWORK = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const SIGN_UP_TYPE = {
  EMAIL: 'email',
  PHONE: 'phone',
  PASSWORD: 'password',
  OTP: 'otp',
  OTP_EMAIL: 'otp_email',
};

export const DEFAULT_CONFIGURATION = {
  primaryButtons: [
    {
      method: SOCIAL_NETWORK.GOOGLE,
      priority: 0,
    },
    {
      method: SOCIAL_NETWORK.FACEBOOK,
      priority: 1,
    },
  ],
  secondaryButtons: [
    {
      method: SIGN_UP_TYPE.PHONE,
      priority: 0,
    },
    {
      method: SIGN_UP_TYPE.OTP,
      priority: 1,
    },
    {
      method: SIGN_UP_TYPE.PASSWORD,
      priority: 2,
    },
  ],
};

export const LOGIN_PAGE_TYPE = {
  LOGIN: 'LOGIN_LOGIN_PAGE_TYPE',
  SIGN_UP: 'SIGN_UP_LOGIN_PAGE_TYPE',
  START_PAGE: 'ACCESS_POINT_LOGIN_PAGE_TYPE',
  BOTTOM_SHEET: 'bottom_sheet',
  PHONE_OTHER_LOGIN: 'phone_other_login',
  SOCIAL_LOGIN: 'social_login',
};

export const NON_ASSOCIATED_USER_CODE = 404;
export const PHONE_TOKEN = 'phoneToken';
export const EMAIL = 'email';
