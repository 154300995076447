/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
/**
 * Utils
 */
import useBaseURL from '@/src/hooks/useBaseURL';
import {
  getSocialLoginRoute,
  getSocialLoginState,
} from '@/src/modules/user/router/service';
import { useCountryShortName } from '@/src/providers/CountryProvider';

/**
 * Constants
 */
import {
  GOOGLE_DEFAULT_REDIRECT_SUCCESS_CALLBACK,
  GOOGLE_DEFAULT_REDIRECT_FAILURE_CALLBACK,
} from '@/src/constants';
import {
  BUTTON_CONTAINER_ELEMENT_ID,
  BUTTON_CONTAINER_ELEMENT_WIDTH,
  GIS_BUTTON_STYLE_ELEMENT_ID,
  GIS_CLIENT_SCRIPT_ELEMENT_ID,
  GIS_LINK_ELEMENT_ID,
} from './constants';
import { LOGIN_PAGE_TYPE } from '../constants';
import { useGoogleConfig } from '../hooks/useGoogleConfig';
const ButtonContainer = styled.div`
  width: ${BUTTON_CONTAINER_ELEMENT_WIDTH}px;
  margin: 0 auto;
`;
const removeGISElementsFromDOM = () => {
  const gisLink = document.getElementById(GIS_LINK_ELEMENT_ID);
  const gisButtonStyle = document.getElementById(GIS_BUTTON_STYLE_ELEMENT_ID);
  const googleClientScript = document.getElementById(
    GIS_CLIENT_SCRIPT_ELEMENT_ID,
  );
  gisLink && gisLink.parentNode.removeChild(gisLink);
  gisButtonStyle && gisButtonStyle.parentNode.removeChild(gisButtonStyle);
  googleClientScript &&
    googleClientScript.parentNode.removeChild(googleClientScript);
};
function GoogleSignInButton({
  origin,
  phoneToken,
  email,
  loginFrom,
  originPath,
  onClick,
}) {
  const [googleClientId, setGoogleClientId] = useState('');
  const [googleSignInRoute, setGoogleSignInRoute] = useState('');
  const [googleSignInState, setGoogleSignInState] = useState({});
  const baseURL = useBaseURL();
  const countryShortName = useCountryShortName();
  const { mutateAsync: googleConfigService } = useGoogleConfig();

  useEffect(() => {
    async function getGoogleConfig() {
      const { success, data } = await googleConfigService();

      if (success) {
        const { clientId } = data;
        // eslint-disable-next-line no-unused-expressions
        clientId && setGoogleClientId(clientId);
      }
    }
    getGoogleConfig();
    return () => removeGISElementsFromDOM();
  }, [googleConfigService]);

  useEffect(() => {
    const socialLoginRoute = getSocialLoginRoute({
      socialNetwork: 'google/one-tap',
    });
    const socialLoginState = getSocialLoginState({
      onSuccessRedirectTo: GOOGLE_DEFAULT_REDIRECT_SUCCESS_CALLBACK,
      onFailureRedirectTo: GOOGLE_DEFAULT_REDIRECT_FAILURE_CALLBACK,
      origin,
      phoneToken,
      email,
      loginFrom,
      originPath,
      countryShortName,
    });

    setGoogleSignInRoute(`${baseURL}${socialLoginRoute}`);
    setGoogleSignInState(socialLoginState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryShortName]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {googleClientId && googleSignInRoute ? (
        <ButtonContainer id={BUTTON_CONTAINER_ELEMENT_ID} onClick={onClick}>
          <div
            id="g_id_onload"
            data-client_id={googleClientId}
            data-login_uri={googleSignInRoute}
            data-state={googleSignInState}
            data-ux_mode="redirect"
            data-auto_prompt="true" // eneable one tap
            data-itp_support="true"
          />
          <div
            className="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="continue_with"
            data-shape="rectangular"
            data-logo_alignment="left"
            data-width={BUTTON_CONTAINER_ELEMENT_WIDTH}
            data-locale="es"
          />
          <Helmet>
            <script
              id={GIS_CLIENT_SCRIPT_ELEMENT_ID}
              src="https://accounts.google.com/gsi/client"
              async
              defer
            ></script>
          </Helmet>
        </ButtonContainer>
      ) : null}
    </>
  );
}

GoogleSignInButton.propTypes = {
  origin: PropTypes.string,
  phoneToken: PropTypes.string,
  email: PropTypes.string,
  loginFrom: PropTypes.string,
  originPath: PropTypes.string,
  onClick: PropTypes.func,
};

GoogleSignInButton.defaultProps = {
  origin: LOGIN_PAGE_TYPE.SOCIAL_LOGIN,
  onClick: () => {},
};

export default GoogleSignInButton;
