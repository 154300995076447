const QUERY_PARAM = {
  PRODUCT_CONFIG: 'p',
  FEATURED_PRODUCT_CONFIG: 'fp',
  LATITUDE: 'lat',
  LONGITUDE: 'lng',
  CITY_NAME: 'city',
  AREA_ID: 'areaId',
  AREA_NAME: 'areaName',
  ADDRESS: 'address',
  CHANNELS: 'channels',
  CATEGORY: 'category',
  BUSINESS_TYPE: 'bt',
  PAGE: 'page',
  FB_LOGIN: 'fbLogin',
  GOOGLE_LOGIN: 'googleLogin',
  ORDER_IN_PROCESS_CODE: 'code',
  ORDER_ID: 'orderId',
  SEARCH: 'search',
  MENU_SECTION: 'menuSection',
  PLATFORM: 'app',
  ORIGIN: 'origin',
  SORT_BY: 'sortBy',
  PAYMENTS: 'payments',
  ONLINE_PAYMENTS: 'onlinePayments',
  MODAL_PAGE: 'modal',
};

const { FB_LOGIN } = QUERY_PARAM;
const { GOOGLE_LOGIN } = QUERY_PARAM;

const FB_LOGIN_SUCCESS_QUERY = `${FB_LOGIN}=true`;
const FB_LOGIN_FAILURE_QUERY = `${FB_LOGIN}=false`;
const GOOGLE_LOGIN_SUCCESS_QUERY = `${GOOGLE_LOGIN}=true`;
const GOOGLE_LOGIN_FAILURE_QUERY = `${GOOGLE_LOGIN}=false`;
const FB_DEFAULT_REDIRECT_SUCCESS_CALLBACK = `/?${FB_LOGIN}=true`;
const FB_DEFAULT_REDIRECT_FAILURE_CALLBACK = `/?${FB_LOGIN}=false`;
const GOOGLE_DEFAULT_REDIRECT_SUCCESS_CALLBACK = `/?${GOOGLE_LOGIN}=true`;
const GOOGLE_DEFAULT_REDIRECT_FAILURE_CALLBACK = `/?${GOOGLE_LOGIN}=false`;

const BUSINESS_TYPE = {
  RESTAURANT: 'RESTAURANT',
  PHARMACY: 'PHARMACY',
  GROCERIES: 'GROCERIES',
  COURIER: 'COURIER',
};

const LOGIN_ORIGIN = {
  LINK_ACCOUNT: 'link_account',
};

const TRACKING_ORIGIN = {
  ORDER_CONFIRM: 'order_confirmation',
  SHOP_LIST: 'shop_list',
  CHANNEL_LANDING: 'channel_landing',
  SWIMLANE: 'swimlane',
  MY_FAVORITES: 'my_favorites',
  MY_STAMPS: 'my_stamps',
  ORDER_STATUS: 'order_status',
  BUTTON: {
    TYPE: 'button',
    CLOSE: 'close',
    OPEN: 'open',
  },
};

const DELIVERY_TYPE = {
  WITH_LOGISITCS: 'withLogistics',
  OWN_DELIVERY: 'ownDelivery',
};

const MODAL_TYPE = {
  DELIVERY_TAG: 'deliveryTag',
  UNAVAILABLE_DELIVERY_TIME: 'checkout_unavailable_delivery_time',
};

module.exports = {
  QUERY_PARAM,
  FB_LOGIN_SUCCESS_QUERY,
  FB_LOGIN_FAILURE_QUERY,
  GOOGLE_LOGIN_SUCCESS_QUERY,
  GOOGLE_LOGIN_FAILURE_QUERY,
  FB_DEFAULT_REDIRECT_SUCCESS_CALLBACK,
  FB_DEFAULT_REDIRECT_FAILURE_CALLBACK,
  GOOGLE_DEFAULT_REDIRECT_SUCCESS_CALLBACK,
  GOOGLE_DEFAULT_REDIRECT_FAILURE_CALLBACK,
  BUSINESS_TYPE,
  LOGIN_ORIGIN,
  TRACKING_ORIGIN,
  DELIVERY_TYPE,
  MODAL_TYPE,
};
