import querystring from 'query-string';
/**
 * Utils
 */
import {
  changePropFromQuery,
  generateURL,
  getRedirectURL,
} from '@/src/utils/url/url';
/**
 * Constants
 */
import ROUTES from '@/src/constants/routes';
/**
 * Returns the redirect url in case of social network login failure
 * @param shouldRedirect
 * @param location
 * @param redirectFailureQuery
 * @returns {string}
 */
export function getRedirectToFailure({
  shouldRedirect = true,
  location,
  redirectFailureQuery,
}) {
  const searchAsObject = {
    ...querystring.parse(redirectFailureQuery),
    ...querystring.parse(location?.search),
  };
  return generateURL({
    pathname: shouldRedirect ? ROUTES.HOME : location.pathname,
    search: querystring.stringify(searchAsObject),
  });
}
/**
 * Returns the redirect url in case of social network login success
 * @param shouldRedirect
 * @param location
 * @param redirectSuccessQuery
 * @returns {string}
 */
export function getRedirectToSuccess({
  shouldRedirect = false,
  location,
  redirectSuccessQuery,
}) {
  const { pathname, search } = shouldRedirect
    ? getRedirectURL({ location, isUserLogged: true })
    : location;
  const fullSearch = changePropFromQuery(search, redirectSuccessQuery, true);
  const searchAsObject = {
    ...querystring.parse(fullSearch),
    ...querystring.parse(location?.search),
  };
  return generateURL({
    pathname,
    search: querystring.stringify(searchAsObject),
  });
}

export function getSocialLoginRoute({ socialNetwork, state }) {
  if (state) {
    const encodedState = encodeURIComponent(JSON.stringify(state));
    return `/api/auth/${socialNetwork}?state=${encodedState}`;
  }
  return `/api/auth/${socialNetwork}`;
}

export function getSocialLoginState({
  onSuccessRedirectTo,
  onFailureRedirectTo,
  origin,
  phoneToken,
  email,
  loginFrom,
  originPath,
  countryShortName,
}) {
  const socialState = JSON.stringify({
    onSuccessTo: onSuccessRedirectTo,
    onFailureTo: onFailureRedirectTo,
    origin,
    phoneToken,
    email,
    loginFrom,
    originPath,
    c: `.${countryShortName}`,
  });

  return socialState;
}

/**
 * replace location to prevent from pushing twice /checkout to history
 * @param onSuccessRedirectTo
 * @param onFailureRedirectTo
 * @param socialQueryRedirect
 * @param origin
 * @param loginFrom
 * @param originPath
 * @param phoneToken
 * @param email
 */
export function redirectToSocialNetwork({
  onSuccessRedirectTo,
  onFailureRedirectTo,
  socialQueryRedirect,
  origin,
  loginFrom,
  originPath,
  phoneToken = undefined,
  email = undefined,
}) {
  window.location.replace(
    getSocialLoginRoute({
      socialNetwork: socialQueryRedirect,
      state: {
        onSuccessTo: onSuccessRedirectTo,
        onFailureTo: onFailureRedirectTo,
        origin,
        phoneToken,
        email,
        loginFrom,
        originPath,
      },
    }),
  );
}
