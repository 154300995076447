/**
 * Utils
 */
import isString from '@/src/utils/string/isString';

// Parent routes that user must be logged in to access them.
const PROTECTED_ROUTES = [];

export function isRouteProtected(route) {
  return isString(route)
    ? PROTECTED_ROUTES.some((r) => route.startsWith(r))
    : false;
}
