import querystring from 'query-string';

/**
 * Constants
 */
import ROUTES from '@/src/constants/routes';
import { QUERY_PARAM } from '@/src/constants';

/**
 * Utils
 */
import isInternalVisitUtil from '@/src/utils/url/isInternalVisit';
import { ignore } from '@/src/utils/object/object';
import { isRouteProtected } from '@/src/services/routes';

export function changePropFromQuery(query, key, value) {
  const queryObj = querystring.parse(query);

  if (value) queryObj[key] = value;
  else delete queryObj[key];

  return querystring.stringify(queryObj);
}

export function generateURL({ pathname, search }) {
  let query = search || '';
  if (query && !query.startsWith('?')) {
    query = `?${query}`;
  }
  return `${pathname}${query}`;
}

export function removeFBParamFromQuery(query) {
  return querystring.stringify(
    ignore(querystring.parse(query), QUERY_PARAM.FB_LOGIN),
  );
}

export function reloadPage({ pathname, search } = {}) {
  if (pathname) {
    let href = pathname;
    if (search) {
      // Remove fbLogin param from query, to avoid showing again welcome toast.
      const searchWithNoFB = removeFBParamFromQuery(search);
      href = generateURL({ pathname, search: searchWithNoFB });
    }
    window.location.href = href; // Force going to server.
  } else {
    window.location.reload();
  }
}

/**
 * @param {string} route full route ex: /restaurantes/montevideo?p=123
 * @param {string} params
 */
export function removeQueryParamsFromURL(route, ...params) {
  const [pathname, query] = route.split('?');
  let search = '';

  if (query && params.length > 0) {
    const searchObj = querystring.parse(`?${query}`);

    params.forEach((p) => {
      delete searchObj[p];
    });

    search = querystring.stringify(searchObj);
  }

  return generateURL({ pathname, search });
}

export function isInternalVisit() {
  return isInternalVisitUtil(document.referrer);
}

export function redirectToExternalUrl(externalUrl) {
  window.location.href = externalUrl;
}

/**
 * @method getRedirectURL returns an URL where the app should be redirected,
 * taking into account the protected routes.
 * @param {object} location DOM Router's location.
 * @param {bool} isUserLogged If user is logged.
 * @param {bool} replace If location should be replaced with location.state.
 */
export function getRedirectURL({
  location,
  isUserLogged, // Don't set default value.
  replace = true,
}) {
  const defaultRedirect = { pathname: ROUTES.HOME, search: '' };
  let redirect = defaultRedirect;
  if (!replace) redirect = location;
  if (isUserLogged === false && isRouteProtected(redirect?.pathname)) {
    redirect = defaultRedirect;
  }
  return redirect;
}

export const validateUrl = (url, validUrls) => {
  try {
    const decodedUri = decodeURIComponent(url);
    const urlObj = new URL(decodedUri);
    const isValidUrl = validUrls.some((validUrl) => urlObj.origin === validUrl);

    return isValidUrl;
  } catch (error) {
    return false;
  }
};
