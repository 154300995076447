import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

/**
 * Assets
 */
import fbLogo from '@/src/assets/theme/images/social/facebook-blue.svg';

/**
 * Components
 */
import BottomSheetItem from '../components/BottomSheetItem';
import {
  SocialButton,
  SocialContainer,
  SocialLogo,
  SocialWrapper,
} from '../components/SocialLoginCommons';

import messages from './messages';

function FacebookButton({
  intl: { formatMessage },
  onClick,
  isBottomSheetButton,
}) {
  return isBottomSheetButton ? (
    <BottomSheetItem
      label={formatMessage(messages.buttonText)}
      onClick={onClick}
    />
  ) : (
    <SocialContainer onClick={onClick}>
      <SocialWrapper>
        <SocialLogo src={fbLogo.src} alt="Facebook" />
      </SocialWrapper>
      <SocialButton label={formatMessage(messages.buttonText)} />
    </SocialContainer>
  );
}

FacebookButton.defaultProps = {
  isBottomSheetButton: false,
};

FacebookButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isBottomSheetButton: PropTypes.bool,
};

export default injectIntl(FacebookButton);
