const packageJSON = require('../package.json');

const { name, applicationName } = packageJSON;

module.exports = {
  vault: {
    url: `https://vault.peya.co/v1/secret/web-micro-site-${name}/dev`,
    enabled: true,
  },
  apiGateway: {
    url: 'https://stg-client-api-gateway.pedidosya.com',
    timeout: 5000,
  },
  authServices: {
    url: 'https://auth.dev.peja.co',
    timeout: 5000,
  },
  cors: {
    whitelist: [],
  },
  session: {
    cookieName: `${applicationName}.${name}.sid`,
    sessionName: `${applicationName}.${name}`,
    cookieConfig: {
      httpOnly: false,
      maxAge: 60000 * 90,
    },
  },
  fwf: {
    httpRequestTimeout: 30000,
    flagTimeToLive: 1,
    localFwfUrl: 'https://fwf-client-api.dev.peja.co',
  },
  gtm: {
    gtmId: 'GTM-K7NSVK5',
    auth: 'lyFrrDjOHN50TtuPAJaB1Q',
    preview: 'env-160',
  },
  consumers: {
    'local-pedidosya-web': {
      key: 'bG9jYWwtcGVkaWRvc3lhLXdlYg==',
      domains: {
        ar: 'http://localhost:3001',
        bo: 'http://localhost:3001',
        cl: 'http://localhost:3001',
        cr: 'http://localhost:3001',
        do: 'http://localhost:3001',
        ec: 'http://localhost:3001',
        gt: 'http://localhost:3001',
        hn: 'http://localhost:3001',
        ni: 'http://localhost:3001',
        pa: 'http://localhost:3001',
        pe: 'http://localhost:3001',
        py: 'http://localhost:3001',
        sv: 'http://localhost:3001',
        uy: 'http://localhost:3001',
        ve: 'http://localhost:3001',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'pedidosya-web': {
      key: 'cGVkaWRvc3lhLXdlYg==',
      domains: {
        ar: 'https://stg-mweb.pedidosya.com.ar',
        bo: 'https://stg-mweb.pedidosya.com.bo',
        cl: 'https://stg-mweb.pedidosya.cl',
        cr: 'https://stg-mweb.pedidosya.cr',
        do: 'https://stg-mweb.pedidosya.com.do',
        ec: 'https://stg-mweb.pedidosya.com.ec',
        gt: 'https://stg-mweb.pedidosya.com.gt',
        hn: 'https://stg-mweb.pedidosya.com.hn',
        ni: 'https://stg-mweb.pedidosyani.com.ni',
        pa: 'https://stg-mweb.pedidosya.com.pa',
        pe: 'https://stg-mweb.pedidosya.com.pe',
        py: 'https://stg-mweb.pedidosya.com.py',
        sv: 'https://stg-mweb.pedidosyasv.com.sv',
        uy: 'https://stg-mweb.pedidosya.com.uy',
        ve: 'https://stg-mweb.pedidosya.com.ve',
      },
      redirectUrl: '/api/auth/forceLogin?c=.{c}&at={at}',
      origins: ['cart/phone-validation', 'checkout'],
      appToken: 'webAppToken',
    },
    'courier-web-business': {
      key: 'Y291cmllci13ZWItYnVzaW5lc3M=',
      domains: {
        ar: 'https://stg-courier-web.pedidosya.com.ar',
        bo: 'https://stg-courier-web.pedidosya.com.bo',
        cl: 'https://stg-courier-web.pedidosya.cl',
        cr: 'https://stg-courier-web.pedidosya.cr',
        do: 'https://stg-courier-web.pedidosya.com.do',
        ec: 'https://stg-courier-web.pedidosya.com.ec',
        gt: 'https://stg-courier-web.pedidosya.com.gt',
        hn: 'https://stg-courier-web.pedidosya.com.hn',
        ni: 'https://stg-courier-web.pedidosyani.com.ni',
        pa: 'https://stg-courier-web.pedidosya.com.pa',
        pe: 'https://stg-courier-web.pedidosya.com.pe',
        py: 'https://stg-courier-web.pedidosya.com.py',
        sv: 'https://stg-courier-web.pedidosyasv.com.sv',
        uy: 'https://stg-courier-web.pedidosya.com.uy',
        ve: 'https://stg-courier-web.pedidosya.com.ve',
      },
      redirectUrl: '/backend/forceLogin?c=.{c}&at={at}',
      appToken: 'courierAppToken',
      termsAndConditionsConfig: {
        businessType: 'COURIER_BUSINESS',
        TYC: '/about/courier-business/tyc',
        PRIVACY_POLICIES: '/about/courier-business/privacy',
      },
    },
  },
};
